body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color:var(--mainText);
    background-color: var(--mainBacground); */
}

.centerContent {
  max-width: 1080px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.addShadow {
  background-color: rgba(0, 0, 0, 0.28);
}

.center {
  text-align: center;
}

@font-face {
  font-family: ETmodules;
  src: url(./assets/fonts/et/modules.eot);
  src: url(./assets/fonts/et/modules.ttf) format("truetype"), url(./assets/fonts/et/modules.woff) format("woff");
  font-weight: 400;
  font-style: normal
}